import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Closing.module.scss'

const ClosingDevices = ({
  laptopImg,
  tabletImg,
  phoneImg,
  laptopImgAlt,
  tabletImgAlt,
  phoneImgAlt,
}) => {
  return (
    <div className={cx.devices}>
      <ElementImage
        className={cx.laptop}
        src={laptopImg}
        alt={`${laptopImgAlt} screenshot on laptop`}
      />
      <ElementImage
        className={cx.tablet}
        src={tabletImg}
        alt={`${tabletImgAlt} screenshot on tablet`}
      />
      <ElementImage
        className={cx.phone}
        src={phoneImg}
        alt={`${phoneImgAlt} screenshot on phone`}
      />
    </div>
  )
}

const Closing = ({
  wrapperClassName,
  bg,
  heading,
  paragraph,
  buttonText,
  buttonLink,
  laptopImg,
  tabletImg,
  phoneImg,
  laptopImgAlt,
  tabletImgAlt,
  phoneImgAlt,
  gradient = 'bg-gradient-slider-200',
  headingClassName = 'heading-3',
  paragraphClassName = 'subtitle-5',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={clsx([cx.gradient], gradient)} />
      <div className={cx.container}>
        <div className={cx.content}>
          <h4 className={headingClassName}>{heading}</h4>
          <p className={paragraphClassName}>{paragraph}</p>
          <Link to={buttonLink}>
            <Button text={buttonText} />
          </Link>
        </div>
        <ClosingDevices
          laptopImg={laptopImg}
          tabletImg={tabletImg}
          phoneImg={phoneImg}
          laptopImgAlt={laptopImgAlt}
          tabletImgAlt={tabletImgAlt}
          phoneImgAlt={phoneImgAlt}
        />
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  laptopImg: PropTypes.string,
  tabletImg: PropTypes.string,
  phoneImg: PropTypes.string,
  laptopImgAlt: PropTypes.string,
  tabletImgAlt: PropTypes.string,
  phoneImgAlt: PropTypes.string,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Closing
