import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import Best10Features from '@components/global/product/Best10Features'
import { Closing } from '@components/pages/best-10-agent-pro'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  featuresData,
  closingData,
} from '@src/data/Best10AgentPro'
import useBest10AgentProMetadata from '@hooks/best-10-agent-pro-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestAgentPro.module.scss'

const Best10AgentProWebsites = () => {
  const {
    bannerImg,
    listMarkLaptop,
    listMarkTablet,
    listMarkPhone,
    listMichelleLaptop,
    listMichelleTablet,
    listMichellePhone,
    listKilianLaptop,
    listKilianTablet,
    listKilianPhone,
    listAngelLaptop,
    listAngelTablet,
    listAngelPhone,
    listFerrettiLaptop,
    listFerrettiTablet,
    listFerrettiPhone,
    listShannaLaptop,
    listShannaTablet,
    listShannaPhone,
    listCityLaptop,
    listCityTablet,
    listCityPhone,
    listDngLaptop,
    listDngTablet,
    listDngPhone,
    listHotLaptop,
    listHotTablet,
    listHotPhone,
    listMatLaptop,
    listMatTablet,
    listMatPhone,
    featuresImg,
    featuresAccent,
    closingBG,
    closingLaptop,
    closingTablet,
    closingPhone,
  } = useBest10AgentProMetadata()

  const imgListData = [
    { laptop: listMarkLaptop, tablet: listMarkTablet, phone: listMarkPhone },
    {
      laptop: listMichelleLaptop,
      tablet: listMichelleTablet,
      phone: listMichellePhone,
    },
    {
      laptop: listKilianLaptop,
      tablet: listKilianTablet,
      phone: listKilianPhone,
    },
    { laptop: listAngelLaptop, tablet: listAngelTablet, phone: listAngelPhone },
    {
      laptop: listFerrettiLaptop,
      tablet: listFerrettiTablet,
      phone: listFerrettiPhone,
    },
    {
      laptop: listShannaLaptop,
      tablet: listShannaTablet,
      phone: listShannaPhone,
    },
    { laptop: listCityLaptop, tablet: listCityTablet, phone: listCityPhone },
    { laptop: listDngLaptop, tablet: listDngTablet, phone: listDngPhone },
    { laptop: listHotLaptop, tablet: listHotTablet, phone: listHotPhone },
    { laptop: listMatLaptop, tablet: listMatTablet, phone: listMatPhone },
  ]
  const isTablet = useMedia(media.tablet)
  const isPhone = useMedia(media.phone)

  return (
    <LayoutInnerPage
      hasTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        hasDarkText
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={
          isPhone || isTablet
            ? bannerData.heading.text
            : bannerData.heading.desktopText
        }
        subheading={bannerData.subheading.text}
        gradient={
          isTablet
            ? bannerData.bgGradient.tabletClassName
            : bannerData.bgGradient.className
        }
        img={extractImage(bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        hasScrollLaptop
        wrapperClassName={cx.list}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
        identifierLayout={listData.list.identifierLayout}
        identifierDelay={listData.list.identifierDelay}
        scrollLaptopBackcover={listData.list.scrollLaptopBackcover}
      />
      <Best10Features
        hasDarkHeaderText
        heading={featuresData.heading.text}
        subheading={featuresData.subheading.text}
        description={featuresData.description.text}
        img={extractImage(featuresImg)}
        accent={extractImage(featuresAccent)}
        imgAlt={featuresData.imgAlt}
        bestItems={featuresData.best}
        highlightsItems={featuresData.highlights}
      />
      <Closing
        bg={extractImage(closingBG)}
        heading={closingData.heading.text}
        paragraph={closingData.paragraph.text}
        buttonText={closingData.button.text}
        buttonLink={closingData.button.link}
        headingClassName={
          isPhone
            ? closingData.heading.phoneClassName
            : closingData.heading.className
        }
        laptopImg={extractImage(closingLaptop)}
        tabletImg={extractImage(closingTablet)}
        phoneImg={extractImage(closingPhone)}
        laptopImgAlt={closingData.alt.laptop}
        tabletImgAlt={closingData.alt.tablet}
        phoneImgAlt={closingData.alt.phone}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10AgentProWebsites
